<template>
  <div
    class="live-feed"
    :class="{ 'close-sidebar': sideNavCollapsed }"
  >
    <CustomPageHeader
      title="Live Feed"
      :subtitle="subTitle"
      :hasDatePicker="false"
      :has-advance-search="true"
      :has-filter-preset="true"
    >
      <template #subtitle-actions>
        <div
          id="alert-review-selector"
          class="flex-align-center"
        >
          <div class="pale-gray mr-2">Show:</div>
          <a-select
            ref="alert-review"
            v-model:value="filterShow"
            :getPopupContainer="(trigger) => trigger.parentNode"
            style="width: 135px"
            @change="onFilterShowChange"
          >
            <a-select-option value="">All messages</a-select-option>
            <a-select-option
              v-if="isDomAdmin"
              value="reviewed"
              >Reviewed</a-select-option
            >
            <a-select-option
              v-if="isDomAdmin"
              value="notreviewed"
              >Not Reviewed</a-select-option
            >
            <a-select-option
              v-if="isDomAdmin"
              value="alerted"
              >Alerted</a-select-option
            >
            <a-select-option
              v-if="isDomAdmin"
              value="notalerted"
              >Not Alerted</a-select-option
            >
            <a-select-option value="read">Already Read</a-select-option>
            <a-select-option value="unread">Unread</a-select-option>
          </a-select>
        </div>
      </template>
    </CustomPageHeader>
    <div
      v-if="isLoading"
      class="text-center pa-4"
    >
      <a-spin />
    </div>
    <div
      v-else
      class="page-content"
      @scroll="onScroll"
    >
      <NoDataFound
        v-if="isInit && (!feedObj.feedData || feedObj.feedData.length === 0)"
      />
      <div class="masonry-wrapper pa-4">
        <div class="masonry">
          <div
            v-for="item in feedObj.feedData"
            :id="`msg-${item.info.id}`"
            :key="item.info.id"
            class="masonry-content"
          >
            <a-card class="message-card">
              <MessageItem
                class="list-item"
                :item="item"
                :order-no="0"
                :show-category="true"
                :has-media="true"
                :widget="true"
                :grid-item="true"
                :window-width="width"
                :update-var="currentFeedTime"
                :show-long-text="true"
                :parentName="'livefeed'"
                @click="drilldown(item)"
              />
            </a-card>
          </div>
        </div>
      </div>
      <div
        v-show="isInit && hasMore"
        class="eof"
      >
        <a-spin />
      </div>
    </div>

    <!-- <SeeMoreDialog /> -->
  </div>
</template>

<script>
import { useStore } from 'vuex';
import {
  reactive,
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
  provide,
} from 'vue';
import dayjs from 'dayjs';

import api from '@/services/api';
import helper from '@/services/helper';

import MessageItem from '@/components/Message/MessageItem.vue';
import NoDataFound from '@/components/Error/NoDataFound.vue';
import CustomPageHeader from '@/components/Layout/CustomPageHeader.vue';
// import SeeMoreDialog from '@/components/Seemore/SeeMoreDialog.vue';

export default {
  name: 'LiveFeed',
  components: {
    MessageItem,
    NoDataFound,
    CustomPageHeader,
    // SeeMoreDialog,
  },
  setup() {
    const { getters, dispatch, state } = useStore();
    const filterCriteria = computed(() => getters['filter/filterCriteria']);
    const isDomAdmin = computed(() => getters['account/isDomAdmin']);

    const showCriteria = ref({});
    const filterShow = ref('');
    const rawStoreShowFeed = localStorage.getItem('stored-show-feed');

    if (rawStoreShowFeed) {
      filterShow.value = rawStoreShowFeed;
    }

    const getFeedData = async (fv, forceRender) => {
      isLoading.value = true;
      const result = await api
        .getLatestFeed({
          ...fv,
          ...showCriteria.value,
          sort: {
            field: 'created_at',
            direction: 'desc',
          },
          highlight: {
            enable: true,
          },
        })
        .catch(() => {});
      if (result && result.message) {
        // console.log(result.message);
        const data = await dispatch('config/highlightKeywords', {
          messageList: result.message.data,
          truncateAt: 0,
        });
        feedObj.feedData = data;
        feedObj.nextCriteria = result.message.nextCriteria;
        feedObj.currentCriteria = result.message.currentCriteria;
        subCategoryName.value = result.message.subCategoryName;
        currentFeedTime.value = dayjs().format('H:mm:ss a');
        if (forceRender) {
          currentFeedTime.value = dayjs().format('H:mm:ss a');
          if (interOb) {
            interOb.disconnect();
            interOb = null;
          }
        }
        if (!interOb) {
          // console.log('INIT INTEROP');

          interOb = new IntersectionObserver(
            (entries) => {
              // console.log(entries);

              if (!entries[0].isIntersecting) {
                // Do nothing on leave
              } else {
                // On freakin enter
                onNextPage();
              }
              // On change
            },
            {
              threshold: 1.0,
              root: document.getElementById('app'),
              rootMargin: '0px',
            }
          );
          setTimeout(() => {
            const elem = document.querySelector('.eof');
            if (elem) {
              interOb.observe(elem);
            }
          });
        }

        if (result.message.data.length > 0) {
          hasMore.value = true;
        } else {
          hasMore.value = false;
        }
        if (filterShow.value) {
          localStorage.setItem('stored-show-feed', filterShow.value);
        }
      } else {
        hasMore.value = false;
      }
      isInit.value = true;
      isLoading.value = false;
    };

    const onFilterShowChange = () => {
      if (filterShow.value === '') {
        showCriteria.value = {};
      } else if (filterShow.value === 'alerted') {
        showCriteria.value = {
          alerted: true,
        };
      } else if (filterShow.value === 'notalerted') {
        showCriteria.value = {
          alerted: false,
        };
      } else if (filterShow.value === 'reviewed') {
        showCriteria.value = {
          reviewed: true,
        };
      } else if (filterShow.value === 'notreviewed') {
        showCriteria.value = {
          reviewed: false,
        };
      } else if (filterShow.value === 'read') {
        showCriteria.value = {
          read: true,
        };
      } else if (filterShow.value === 'unread') {
        showCriteria.value = {
          read: false,
        };
      }
      getFeedData(filterCriteria.value);
    };

    const feedObj = reactive({
      feedData: [],
      nextCriteria: {},
      currentCriteria: {},
    });

    const subCategoryName = ref({});

    const sideNavCollapsed = computed(() => state.sideNavCollapsed);

    const isInit = ref(false);
    const isLoading = ref(true);
    const hasMore = ref(true);
    let interOb = null;
    let loadingMore = false;
    let feedInterval = null;

    // get Data
    const currentFeedTime = ref(dayjs().format('H:mm:ss a'));
    const onNextPage = async () => {
      // From last element find from created_at -1
      if (!loadingMore) {
        loadingMore = true;
        const args = JSON.parse(JSON.stringify(feedObj.currentCriteria));
        let currentData = feedObj.feedData;
        const currentLength = currentData.length;
        const lastItem = currentData[currentLength - 1];
        args.time = {
          untilDate: lastItem.info.created_at - 1,
        };
        // args.highlight = {
        //   enable: true,
        // };
        const result = await api.getOlderLatestFeed(args).catch(() => {});
        if (result && result.message) {
          feedObj.currentCriteria = result.message.criteria;
          // Concat data
          const data = await dispatch('config/highlightKeywords', {
            messageList: result.message.data,
            truncateAt: 0,
          });
          const newData = currentData.concat(data);
          feedObj.feedData = newData;
          if (result.message.data.length > 0) {
            hasMore.value = result.message.data.length > 0;
          }
          loadingMore = false;
        }
      }
    };

    const getNewerFeedData = async () => {
      let oldData = feedObj.feedData;
      const result = await api
        .getLatestFeed(feedObj.nextCriteria)
        .catch(() => {});
      if (result && result.message) {
        const data = await dispatch('config/highlightKeywords', {
          messageList: result.message.data,
          truncateAt: 0,
        });
        feedObj.nextCriteria = result.message.nextCriteria;
        currentFeedTime.value = dayjs().format('H:mm:ss a');
        feedObj.feedData = data.concat(oldData);
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 300);
      }
    };

    const subTitle = computed(() => {
      return `Result at ${currentFeedTime.value}`;
    });

    // Width track
    let windowWidth = ref(window.innerWidth);
    const onWidthChange = () => {
      // console.log('Resize', window.innerWidth);
      windowWidth.value = window.innerWidth;
    };

    const drilldown = (item) => {
      dispatch('seemore/show', item);
    };

    watch(
      () => filterCriteria.value,
      () => {
        getFeedData(filterCriteria.value, true);
      }
    );

    onMounted(async () => {
      await dispatch('config/getAccountKeyword');
      // resize
      window.addEventListener('resize', onWidthChange);
      // feed interval
      feedInterval = setInterval(() => {
        getNewerFeedData();
      }, 60000);
      getFeedData(filterCriteria.value);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onWidthChange);
      if (feedInterval) {
        clearInterval(feedInterval);
      }
      if (interOb) {
        interOb.disconnect();
      }
    });
    const width = computed(() => windowWidth.value);

    provide('onEditMessage', (e) => {
      helper.saveLocalMessage(e, feedObj.feedData);
    });
    provide('showMode', filterShow);

    provide('subCategoryName', subCategoryName);

    // hide popover when scrolling
    const onScroll = () => {
      const scrollElement = document.getElementsByClassName('ant-popover');
      if (scrollElement) {
        for (let ele of scrollElement) {
          ele.style.display = 'none';
        }
      }
    };

    return {
      isInit,
      isLoading,
      hasMore,
      feedObj,
      width,
      currentFeedTime,
      subTitle,
      drilldown,
      sideNavCollapsed,
      onScroll,
      isDomAdmin,
      filterShow,
      onFilterShowChange,
    };
  },
};
</script>

<style lang="scss">
.live-feed {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .page-content {
    padding: 20px;
    overflow: auto;
  }
  .title-text {
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    color: #272b41;
  }
  .time-text {
    margin-left: 30px;
    font-size: 14px;
    color: #5a5f7d;
  }
  .masonry-wrapper {
    padding: 1.5em;
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
  }
  .masonry {
    display: grid;
    grid-template-columns: repeat(1, minmax(100px, 1fr));
    grid-gap: 24px;
    grid-auto-rows: 8px;
  }
  @media only screen and (max-width: 1600px) and (min-width: 1200px) {
    .masonry {
      grid-template-columns: repeat(2, minmax(100px, 1fr));
    }
  }
  @media only screen and (min-width: 1600px) {
    .masonry {
      grid-template-columns: repeat(3, minmax(100px, 1fr));
    }
  }
  .masonry-item,
  .masonry-content {
    border-radius: 10px;
    overflow: hidden;
  }
  .eof {
    text-align: center;
    padding: 20px;
  }
  .to-top-button {
    position: fixed;
    bottom: 50px;
    right: 50px;
  }
  // If close the sidebar should expand to 3 row
  &.close-sidebar {
    @media only screen and (max-width: 1333px) and (min-width: 1000px) {
      .masonry {
        grid-template-columns: repeat(2, minmax(100px, 1fr));
      }
    }
    @media only screen and (max-width: 1600px) and (min-width: 1333px) {
      .masonry {
        grid-template-columns: repeat(3, minmax(100px, 1fr));
      }
    }
  }
}
</style>
